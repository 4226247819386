import React from 'react';
import Paperclip from './Paperclip';
import Star from './Star';
import Search from './Search';
import Link from './Link';
import Plus from './Plus';
import Cross from './Cross';
import Account from './Account';
import Edit from './Edit';
import Message from './Message';
import Mail from './Mail';
import Phone from './Phone';
import ActiveLinkArrow from './ActiveLinkArrow';
import UnActiveLinkArrow from './UnActiveLinkArrow';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import Claim from './Claim';
import Close from './Close';
import LeftAngle from './LeftAngle';
import StarUnactive from './StarUnactive';
import StarActive from './StarActive';
import Tick from './Tick';
import Download from './Download';
import Chain from './Chain';
import Download2 from './Download2';
import RightArrow2 from './RightArrow2';
import PlayButton from './PlayButton';
import MapLocation from './MapLocation';
import File from './File';
import Info from './Info';
import VideoThumbnail from './VideoThumbnail';
import TrashCan from './TrashCan';
import Warning from './Warning';
import Warning2 from './Warning2';
import ArchiveBox from './ArchiveBox';
import Unarchive from './Unarchive';
import Duplicate from './Duplicate';
import Question from './Question';
import SearchDelivered from './SearchDelivered';
import Analytics from './Analytics';
import InfoCircle from './InfoCircle';
import JournalText from './JournalText';
import InfoCircleDark from './InfoCircleDark';

import './styles.scss';
import ChangeAgentV1 from './ChangeAgentV1';
import ChangeAgentV2 from './ChangeAgentV2';
import Handshake from './Handshake';
import Person from './Person';

const Icons = ({
  width,
  height,
  className,
  iconName,
  fill,
  onClick,
  style,
}) => {
  switch (iconName) {
    case 'paperclip':
      return (
        <Paperclip
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'star':
      return (
        <Star width={width} height={height} fill={fill} className={className} />
      );
    case 'search':
      return (
        <Search
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'searchDelivered':
      return (
        <SearchDelivered
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'analytics':
      return (
        <Analytics
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'infoCircle':
      return (
        <InfoCircle
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'infoCircleDark':
      return (
        <InfoCircleDark
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'journalText':
      return (
        <JournalText
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'link':
      return (
        <Link width={width} height={height} fill={fill} className={className} />
      );
    case 'plus':
      return (
        <Plus width={width} height={height} fill={fill} className={className} />
      );
    case 'cross':
      return (
        <Cross
          width={width}
          height={height}
          fill={fill}
          className={className}
          style={style}
        />
      );
    case 'account':
      return (
        <Account
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'edit':
      return (
        <Edit width={width} height={height} fill={fill} className={className} />
      );
    case 'download':
      return (
        <Download
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );

    case 'message':
      return (
        <Message
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'mail':
      return (
        <Mail width={width} height={height} fill={fill} className={className} />
      );
    case 'phone':
      return (
        <Phone
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'activeLinkArrow':
      return (
        <ActiveLinkArrow
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'unActiveLinkArrow':
      return (
        <UnActiveLinkArrow
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'leftArrow':
      return (
        <LeftArrow
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'rightArrow':
      return (
        <RightArrow
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );

    case 'claim':
      return (
        <Claim
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'close':
      return (
        <Close
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'leftAngle':
      return (
        <LeftAngle
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'starUnactive':
      return (
        <StarUnactive
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'starActive':
      return (
        <StarActive
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'tick':
      return (
        <Tick
          width={width}
          height={height}
          fill={fill}
          className={className}
          style={style}
        />
      );
    case 'chain':
      return (
        <Chain
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'rightArrow2':
      return (
        <RightArrow2
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'playButton':
      return (
        <PlayButton
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'mapLocation':
      return (
        <MapLocation
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'file':
      return (
        <File width={width} height={height} fill={fill} className={className} />
      );
    case 'info':
      return (
        <Info width={width} height={height} fill={fill} className={className} />
      );
    case 'download2':
      return (
        <Download2
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'VideoThumbnail':
      return (
        <VideoThumbnail
          width={width}
          height={height}
          fill={fill}
          className={className}
        />
      );
    case 'trashcan':
      return (
        <TrashCan
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'changeAgentV1':
      return (
        <ChangeAgentV1
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'changeAgentV2':
      return (
        <ChangeAgentV2
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'handshake':
      return (
        <Handshake
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'person':
      return (
        <Person
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'warning':
      return (
        <Warning
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'warning2':
      return (
        <Warning2
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'archive_box':
      return (
        <ArchiveBox
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'unarchive':
      return (
        <Unarchive
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'duplicate':
      return (
        <Duplicate
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    case 'question':
      return (
        <Question
          width={width}
          height={height}
          fill={fill}
          className={className}
          onClick={onClick}
        />
      );
    default:
      return null;
  }
};

export default Icons;
