import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import MultilineInput from '../MultilineInput';
import Button from '../Button';
import Select from '../Select';
import SelectTitle from '../SelectTitle';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import Autocomplete from '../Autocomplete';
import Association from '../Association';
import Info from '../Info';
import FileLoader from '../FileLoader';
import VideoLoader from '../VideoLoader';
import { VideoThumbnail } from '../VideoPlayer';
import MultipleFileLoader from '../MultipleFileLoader';
import InternationalPhoneInput from '../InternationalPhoneInput';
import GoogleAutocomplete from '../GoogleAutocomplete';
import SmartNameInput from '../SmartNameInput';
import AddressComplete from '../ClickToAddress';
import MultiSelect from '../MultiSelect';
import AmountInput from '../AmountInput';
import CustomCheckbox from 'components/CustomCheckbox';
import { VehicleInspectorPlaceholder } from '../VehicleInspector';

import { setHTML, containsEscapedHTML, MAX_ANSWER_LENGTH } from 'utils/helpers';
import { ANSWER_TYPES } from 'utils/constants';
import MediaUpload from './MediaUpload';
import UniversalFileLoader from './UniversalFileLoader';
import FilteredSearch from './FilteredSearch';
import Message from './Message';

const Field = (props) => {
  const {
    onChange = () => {},
    onClick = () => {},
    onKeyPress = () => {},
    labelClass,
    value,
    dangerouslySetInnerHTML,
    element,
    parsedJsonAnswer,
    disabled,
    answerType,
  } = props;

  console.log('answerType :>> ', answerType);

  const disabledField =
    disabled || (answerType && answerType === ANSWER_TYPES.message);

  switch (element) {
    case 'input': {
      const element =
        value && (value.length >= MAX_ANSWER_LENGTH || parsedJsonAnswer) ? (
          <MultilineInput
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={!containsEscapedHTML(value) ? value : null}
            dangerouslySetInnerHTML={
              dangerouslySetInnerHTML
                ? {
                    __html: dangerouslySetInnerHTML,
                  }
                : containsEscapedHTML(value)
                ? setHTML(value)
                : null
            }
            {...props}
          />
        ) : (
          <Input
            onChange={onChange}
            onKeyPress={onKeyPress}
            labelClass={labelClass}
            value={!containsEscapedHTML(value) ? value : null}
            dangerouslySetInnerHTML={
              dangerouslySetInnerHTML
                ? {
                    __html: dangerouslySetInnerHTML,
                  }
                : containsEscapedHTML(value)
                ? setHTML(value)
                : null
            }
            {...props}
            disabled={disabledField}
          />
        );

      return element;
    }
    case 'amountInput': {
      return (
        <AmountInput
          {...props}
          onChange={onChange}
          onKeyPress={onKeyPress}
          labelClass={labelClass}
          value={!containsEscapedHTML(value) ? value : null}
          dangerouslySetInnerHTML={
            dangerouslySetInnerHTML
              ? {
                  __html: dangerouslySetInnerHTML,
                }
              : containsEscapedHTML(value)
              ? setHTML(value)
              : null
          }
        />
      );
    }

    case 'multilineInput':
      return <Input {...props} />;
    case 'button':
      return <Button {...props} onClick={onClick} />;
    case 'select':
      return <Select {...props} onChange={onChange} onKeyPress={onKeyPress} />;
    case 'selectTitle':
      return (
        <SelectTitle {...props} onChange={onChange} onKeyPress={onKeyPress} />
      );
    case 'multiSelect':
      return (
        <MultiSelect {...props} onChange={onChange} onKeyPress={onKeyPress} />
      );
    case 'date':
      return (
        <DatePicker {...props} onChange={onChange} onKeyPress={onKeyPress} />
      );
    case 'time':
      return (
        <TimePicker {...props} onChange={onChange} onKeyPress={onKeyPress} />
      );
    case 'autocomplete':
      return (
        // HERE
        <Autocomplete {...props} onChange={onChange} onKeyPress={onKeyPress} />
      );
    case 'contactsAutocomplete':
      return (
        <Association {...props} onChange={onChange} onKeyPress={onKeyPress} />
      );
    case 'info':
      return <Info {...props} />;
    case 'file':
      // return <UniversalFileLoader {...props} />;
      return <FileLoader {...props} />;
    case 'multipleFile':
      return <MultipleFileLoader {...props} />;
    case 'video':
      return <VideoLoader {...props} />;
    case 'videoThumbnail':
      return <VideoThumbnail {...props} onClick={onClick} />;
    case 'internationalPhone':
      return (
        <InternationalPhoneInput
          {...props}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      );
    case 'googleAutocomplete':
      return (
        <GoogleAutocomplete
          {...props}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      );
    case 'name':
      return (
        <SmartNameInput
          {...props}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      );
    case 'clickToAddress':
      return (
        <AddressComplete
          {...props}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      );
    case 'checkbox':
      return (
        <CustomCheckbox
          {...props}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      );
    case 'bdeo':
      return <VehicleInspectorPlaceholder {...props} />;
    case ANSWER_TYPES.mediaUpload:
      return <MediaUpload {...props} />;
    case ANSWER_TYPES.fileUpload:
      return <UniversalFileLoader {...props} />;
    case ANSWER_TYPES.filteredSearch:
      return <FilteredSearch {...props} />;
    case ANSWER_TYPES.message:
      return <Message {...props} disabled={disabledField} />;

    default:
      return <Input {...props} />;
  }
};

Field.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  element: PropTypes.string.isRequired,
};

export default Field;
